<template>
  <div class="w-100">
    <TTabs
      :tabs="tabs"
      :activeTab="$route.query.tab"
      @change-tab="changeTab"
      mountOnEnter
    >
      <template #same-currency-transfer>
        <TransactionTransfer action="same_currency_transfer" />
      </template>
      <template #other-currency-transfer>
        <TransactionTransfer action="other_currency_transfer" />
      </template>
    </TTabs>
  </div>
</template>

<script>
import TransactionTransfer from "./TransactionTransfer.vue";

export default {
  components: {
    TransactionTransfer,
  },
  data() {
    return {
      tabs: [
        {
          key: "same-currency-transfer",
          name: "Same currency transfer",
          icon: "cisLibraryBooks",
        },
        {
          key: "other-currency-transfer",
          name: "Other currency transfer",
          icon: "cisLibraryBooks",
        },
      ],
    };
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
