<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap justify-content-start">
      <div class="d-flex align-items-center">
        <TMessage
          v-if="action === 'same_currency_transfer'"
          content="Same currency transfer"
          bold
        />
        <TMessage
          v-if="action !== 'same_currency_transfer'"
          content="Other currency transfer"
          bold
        />
        <TSpinner :loading="loading" />
      </div>
      <div class="ml-auto">
        <TButtonClear
          variant="outline"
          color="primary"
          class="mr-1"
          @click="clearInput"
        />
        <TButton
          content="Create"
          icon="cis-plus"
          @click="create"
          :options="{ disabled: loading }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="7">
          <CRow>
            <CCol col="6">
              <SSelectCard
                label="From card"
                class="mb-3"
                :value.sync="input.card_id"
              />
            </CCol>
            <CCol md="6">
              <TInputText
                label="Ref code"
                placeholder="Ref code"
                class="mb-3"
                :value.sync="input.code_ref"
              />
            </CCol>
          </CRow>
          <CRow v-if="action === 'same_currency_transfer'">
            <CCol col="6">
              <SSelectCard
                label="To card"
                class="mb-3"
                :value.sync="to_card.card_id"
              />
            </CCol>
            <CCol md="6">
              <TInputText
                label="Ref code"
                placeholder="Ref code"
                class="mb-3"
                :value.sync="to_card.code_ref"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <CRow>
                <CCol>
                  <TInputDateTime
                    label="Voucher date"
                    class="mb-3"
                    :value.sync="input.voucher_date"
                  />
                </CCol>
                <CCol>
                  <SSelectCurrency
                    label="Currency"
                    :prepend="[]"
                    :value.sync="input.currency_id"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6" v-if="action !== 'same_currency_transfer'">
              <TInputNumber
                label="Exchange rate"
                :value.sync="input.exchange_rate"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="9">
              <TInputMoney
                label="Transfer amount"
                class="mb-3"
                :currency="input.currency_id"
                :value.sync="input.amount"
              />
            </CCol>
            <CCol md="3">
              <TInputMoney
                label="Transfer fee"
                class="mb-3"
                :currency="input.currency_id"
                :value.sync="input.transfer_fee_amount"
              />
            </CCol>
          </CRow>
          <TInputTextarea
            label="Description"
            placeholder="Description"
            class="mb-3"
            :value.sync="input.description"
          />
          <hr v-if="action !== 'same_currency_transfer'" />
          <CRow v-if="action !== 'same_currency_transfer'">
            <CCol col="6">
              <SSelectCard
                label="To card"
                class="mb-3"
                :value.sync="to_card.card_id"
              />
            </CCol>
            <CCol md="6">
              <TInputText
                label="Ref code"
                placeholder="Ref code"
                class="mb-3"
                :value.sync="to_card.code_ref"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <CRow>
                <CCol v-if="action !== 'same_currency_transfer'">
                  <TInputDateTime
                    label="Voucher date"
                    class="mb-3"
                    :value.sync="to_card.voucher_date"
                  />
                </CCol>
                <CCol v-if="action !== 'same_currency_transfer'">
                  <SSelectCurrency
                    label="Currency"
                    :prepend="[]"
                    :value.sync="to_card.currency_id"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12" v-if="action !== 'same_currency_transfer'">
              <TInputMoney
                label="Receive amount"
                class="mb-3"
                :currency="to_card.currency_id"
                :value.sync="to_card.amount"
                :inputOptions="{ disabled: true }"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12" v-if="action !== 'same_currency_transfer'">
              <TInputTextarea
                label="Description"
                placeholder="Description"
                class="mb-3"
                :value.sync="to_card.description"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol md="5">
          <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
    },
  },
  data() {
    return {
      input: {
        exchange_rate: 1,
      },
      to_card: {},
      receipts: [],
      loading: false,
    };
  },
  computed: {
    itemFields() {
      return [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Count",
          showInput: false,
        },
        { key: "customer", label: "Customer", _style: "min-width: 200px" },
        {
          key: "description",
          label: "Description",
          _style: "min-width: 300px",
          placeholder: "Description",
        },
        {
          key: "amount",
          label: "Amount",
          _style: "min-width: 200px",
          showTotal: true,
          totalLabel: "Total",
          currency_id: this.input.currency_id,
          showInput: true,
        },
      ];
    },
  },
  watch: {
    "input.exchange_rate"(er) {
      this.to_card.amount = er * this.input.amount;
    },
    "input.amount"(amount) {
      this.to_card.amount = amount * this.input.exchange_rate;
    },
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch(
          `accounting.transactions_transfer.${this.action}`,
          this.getDataCreate()
        )
        .then(() => {
          this.clearInput();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDataCreate() {
      let params = { ...this.lodash.omit(this.input, ["transfer_fee_amount"]) };
      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index}][receiptable_type]`] = "file";
        params[`receipts[${index}][file]`] = receipt.file;
      });
      this.lodash.each(this.to_card, (value, key) => {
        params[`to_card[${key}]`] = value;
      });
      params[`transfer_fee[amount]`] = this.input.transfer_fee_amount;

      if (this.action === "same_currency_transfer") {
        params[`to_card[currency_id]`] = this.input.currency_id;
        params[`to_card[description]`] = this.input.description;
      }

      return params;
    },
    clearInput() {
      this.input = {
        exchange_rate: 1,
      };
      this.to_card = {};
      this.receipts = [];
    },
  },
};
</script>
